<template>
  <div>
    <v-form
      class="d-flex align-center"
      ref="form"
      v-model="formValid"
      @submit.prevent="getPayment(false)"
    >
      <v-row class="ma-0 pa-4 align-center">
        <v-col class="pa-0">
          <div class="d-flex flex-wrap align-center">
            <div class="px-2 fsize14">
              <div class="pb-2">User ID</div>
              <v-text-field
                outlined
                dense
                placeholder="Enter the UserId"
                v-model="userId"
              ></v-text-field>
            </div>

            <div class="px-2">
              <div class="pb-1">*From Date</div>
              <v-menu
                v-model="dateMenu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    @click:append="dateMenu1 = true"
                    class="apiKeyInput"
                    v-model="computedDateFormatted1"
                    outlined
                    persistent-hint
                    :menu-props="{ bottom: true, offsetY: true }"
                    :rules="fromDateRules"
                    placeholder="Select the From Date"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  :show-current="true"
                  no-title
                  @input="dateMenu1 = false"
                >
                </v-date-picker>
              </v-menu>
            </div>

            <div class="px-2">
              <div class="pb-1">*To Date</div>
              <v-menu
                v-model="dateMenu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    @click:append="dateMenu2 = true"
                    class="apiKeyInput"
                    v-model="computedDateFormatted2"
                    outlined
                    persistent-hint
                    :menu-props="{ bottom: true, offsetY: true }"
                    :rules="toDateRules"
                    placeholder="Select the To Date"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  :show-current="true"
                  no-title
                  @input="dateMenu2 = false"
                >
                </v-date-picker>
              </v-menu>
            </div>

            <div class="">
              <v-btn
                height="40"
                type="submit"
                depressed
                class="text-capitalize fsize12 mx-4"
                color="primary"
                >Submit
              </v-btn>
            </div>

            <div class="d-flex pa-4" v-if="paymentList.length > 0">
              <v-btn
                outlined
                height="40"
                min-width="fit-content"
                color="primary"
                class="text-capitalize mr-4 fsize12 outlined"
                @click="downloadTable(paymentList)"
              >
                Download
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      id="tableData"
      v-if="paymentList.length > 0 && !loading"
      :headers="titlename"
      :items="paymentList"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:item="props">
        <tr class="pointer">
          <td class="w-50px">
            {{
              getDateString(
                new Date(props.item.createdOn.split(" ")[0]),
                "d-M-y"
              )
            }}
          </td>
          <td class="">{{ props.item.clientId }}</td>
          <td class="">{{ props.item.fullAccountNo }}</td>
          <td class="">{{ props.item.amount }}</td>
          <td class="">{{ props.item.orderNo }}</td>
          <td class="">{{ props.item.vpa }}</td>

          <td class="">{{ props.item.segment }}</td>
          <td class="">{{ props.item.boUpdated }}</td>
          <td class="">{{ props.item.source }}</td>
          <td class="">
            {{
              props.item.transactionStatus ? props.item.transactionStatus : "NA"
            }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <div v-else-if="!loading">
      <div class="text-center pa-5">
        <v-icon class="display-2 pb-4">mdi-thought-bubble-outline</v-icon>
        <div class="">Data Not Found</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import JsonViewer from "vue-json-viewer";
Vue.use(JsonViewer);
import { mapGetters } from "vuex";
import commonFunc from "../../mixins/commonFunctions";
export default {
  name: "EdisList",
  mixins: [commonFunc],

  data: () => ({
    dialog: false,
    currentJson: {},
    formatFlag: true,
    copyText: "Copy",
    //
    date: new Date().toISOString().substr(0, 10),
    dateMenu: false,
    formValid: true,
    titlename: [
      { text: "Created On", sortable: false, value: "createdOn" },
      { text: "client ID", sortable: false, value: "clientId" },
      {
        text: "A/C No",
        align: "start",
        sortable: false,
        value: "fullAccountNo",
      },
      { text: "Amount", sortable: false, value: "amount" },
      { text: "Order No", sortable: false, value: "orderNo" },
      { text: "VPA", sortable: false, value: "vpa" },
      { text: "Segment", sortable: false, value: "segment" },
      { text: "Bo Updated", sortable: false, value: "boUpdated" },
      { text: "Source", sortable: false, value: "source" },
      { text: "Status", sortable: false, value: "transactionStatus" },
    ],
    JsonFields: [
      "Bold",
      "ClientId",
      "ReqId",
      "ReqType",
      "ResId",
      "ResStatus",
      "ResTime",
      "ResError",
      "Remarks",
      "TxnReqId",
      "TxnId",
      "Status",
      "ErrorCode",
      "Quantity",
      "Isin",
      "PlacedAt",
      "SettlementNo",
      "ExecDate",
    ],
    userId: "",
    dateMenu1: false,
    dateMenu2: false,
    fromDate: null,
    toDate: null,
    fromDateRules: [(v) => !!v || "From Date is required"],
    toDateRules: [(v) => !!v || "To Date is required"],
  }),

  computed: {
    ...mapGetters({
      loading: "getLoader",
      search: "getSearch",
    }),
    ...mapGetters("reports", {
      paymentList: "getPaymentList",
      edisDownloadData: "getEdisDownloadData",
    }),
    computedDateFormatted1: {
      get() {
        return this.VutifyFormatDate(this.fromDate,'/');
      },
      set() {},
    },
    computedDateFormatted2: {
      get() {
        return this.VutifyFormatDate(this.toDate,'/');
      },
      set() {},
    },
  },

  watch: {
    dialog(val) {
      this.formatFlag = true;
    },
  },

  created() {
    this.getPayment(true);
  },

  methods: {
    async getPayment(val) {
      if (val || this.$refs.form.validate()) {
        let jsonObj = {
          fromDate: this.fromDate,
          toDate: this.toDate,
          clientId: this.userId,
        };
        await this.$store.dispatch("reports/getPayment", jsonObj);
      }
    },

    callDialog(data) {
      data && data != `""` ? (this.dialog = true) : "";
      this.currentJson = data;
    },

    format() {
      this.formatFlag ? (this.currentJson = JSON.parse(this.currentJson)) : "";
      this.formatFlag = !this.formatFlag;
    },

    copyTextFunc() {
      navigator.clipboard.writeText(JSON.stringify(this.currentJson));
      this.copyText = "Copied";
      setTimeout(() => {
        this.copyText = "Copy";
      }, 2500);
    },

    createAndDownload(download = "edis.txt", type = "text/plain") {
      let content = this.edisDownloadData;
      const el = (sel, par) => (par || document).querySelector(sel);
      const elNew = (tag, prop) =>
        Object.assign(document.createElement(tag), prop);

      const file = new Blob([content], { type });
      const href = URL.createObjectURL(file);
      const elAnchor = elNew("a", { href, download });
      el("body").append(elAnchor);
      elAnchor.click();
      elAnchor.remove();
      URL.revokeObjectURL(href);
    },

    downloadTable(data) {
      this.downloadUnicodeCSV(data, "payment");
    },
  },
};
</script>
